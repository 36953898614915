.Title {
  padding:33px;
}

.TitleText {
  font-family: Verdana;
  font-size: 24px;
  color: #3B3B3D;
  letter-spacing: 2px;
  text-align: center;
  padding: 45px 0px 45px 0px;
}

.TitleHighlight {
  color: #0D7A7B;
}